.react-switch-checkbox {
  background-color: rgb(236, 236, 236) !important;
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  background-color: rgb(236, 236, 236) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 140px;
  height: 38px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  background-color: rgb(161, 178, 184) !important;
  border-radius: 4px;
  content: '';
  position: absolute;
  top: 1px;
  left: 2px;
  width: 68px;
  height: 36px;
  border-radius: 45px;
  transition: 0.3s;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 55px;
}

.react-switch-checked-title {
  color: white;
  height: 36px;
  line-height: 36px;
  letter-spacing: 1px;
  text-align: center;
  transition: color 0.3s;
  width: 68px;
  z-index: 5;
}

.react-switch-unchecked-title {
  color: #151515;
  height: 36px;
  line-height: 36px;
  letter-spacing: 1px;
  text-align: center;
  transition: color 0.3s;
  width: 68px;
  z-index: 5;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-checked-title {
  color: #151515 !important;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-unchecked-title {
  color: white !important;
}
